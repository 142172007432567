/**
 * @generated SignedSource<<c3bcdf687132a1a8c8dfa44d25ee3a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type landingPageQuery$variables = {};
export type landingPageQuery$data = {
  readonly viewer: {
    readonly isGuestCheckoutEligible: boolean | null;
  };
};
export type landingPageQuery = {
  response: landingPageQuery$data;
  variables: landingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isGuestCheckoutEligible",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "landingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "landingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f97d2b3180b7e38cc208c649b70911fe",
    "id": null,
    "metadata": {},
    "name": "landingPageQuery",
    "operationKind": "query",
    "text": "query landingPageQuery {\n  viewer {\n    isGuestCheckoutEligible\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bcb5144e126d1d264c19d287a0d65f1";

export default node;
